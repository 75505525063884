<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M9.99998 7.33331H8.66665V4.66665C8.66665 4.48984 8.59641 4.32027 8.47139 4.19524C8.34636 4.07022 8.17679 3.99998 7.99998 3.99998C7.82317 3.99998 7.6536 4.07022 7.52858 4.19524C7.40355 4.32027 7.33332 4.48984 7.33332 4.66665V7.99998C7.33332 8.17679 7.40355 8.34636 7.52858 8.47138C7.6536 8.59641 7.82317 8.66665 7.99998 8.66665H9.99998C10.1768 8.66665 10.3464 8.59641 10.4714 8.47138C10.5964 8.34636 10.6666 8.17679 10.6666 7.99998C10.6666 7.82317 10.5964 7.6536 10.4714 7.52858C10.3464 7.40355 10.1768 7.33331 9.99998 7.33331ZM7.99998 1.33331C6.68144 1.33331 5.39251 1.72431 4.29618 2.45685C3.19985 3.18939 2.34537 4.23058 1.84079 5.44876C1.3362 6.66693 1.20418 8.00738 1.46141 9.30058C1.71865 10.5938 2.35359 11.7817 3.28594 12.714C4.21829 13.6464 5.40617 14.2813 6.69938 14.5385C7.99259 14.7958 9.33303 14.6638 10.5512 14.1592C11.7694 13.6546 12.8106 12.8001 13.5431 11.7038C14.2757 10.6075 14.6666 9.31852 14.6666 7.99998C14.6666 7.1245 14.4942 6.25759 14.1592 5.44876C13.8241 4.63992 13.3331 3.90499 12.714 3.28593C12.095 2.66688 11.36 2.17581 10.5512 1.84078C9.74237 1.50575 8.87546 1.33331 7.99998 1.33331ZM7.99998 13.3333C6.94515 13.3333 5.914 13.0205 5.03694 12.4345C4.15988 11.8484 3.47629 11.0155 3.07263 10.041C2.66896 9.06642 2.56334 7.99406 2.76913 6.9595C2.97492 5.92493 3.48287 4.97462 4.22875 4.22874C4.97463 3.48286 5.92494 2.97491 6.9595 2.76912C7.99407 2.56334 9.06642 2.66895 10.041 3.07262C11.0155 3.47629 11.8485 4.15988 12.4345 5.03694C13.0205 5.914 13.3333 6.94515 13.3333 7.99998C13.3333 9.41447 12.7714 10.771 11.7712 11.7712C10.771 12.7714 9.41447 13.3333 7.99998 13.3333Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if(props.fill){
                return props.fill
            }
            
            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>