<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M11.6667 10C11.6667 9.67037 11.5689 9.34813 11.3858 9.07405C11.2027 8.79997 10.9424 8.58635 10.6378 8.4602C10.3333 8.33406 9.99817 8.30105 9.67487 8.36536C9.35157 8.42967 9.0546 8.5884 8.82151 8.82149C8.58842 9.05458 8.42969 9.35155 8.36538 9.67485C8.30107 9.99815 8.33407 10.3333 8.46022 10.6378C8.58637 10.9424 8.79999 11.2026 9.07407 11.3858C9.34815 11.5689 9.67038 11.6667 10 11.6667C10.442 11.6667 10.866 11.4911 11.1785 11.1785C11.4911 10.866 11.6667 10.442 11.6667 10ZM11.6667 4.16667C11.6667 3.83703 11.5689 3.5148 11.3858 3.24072C11.2027 2.96664 10.9424 2.75301 10.6378 2.62687C10.3333 2.50072 9.99817 2.46772 9.67487 2.53203C9.35157 2.59633 9.0546 2.75507 8.82151 2.98816C8.58842 3.22124 8.42969 3.51822 8.36538 3.84152C8.30107 4.16482 8.33407 4.49993 8.46022 4.80447C8.58637 5.10902 8.79999 5.36931 9.07407 5.55245C9.34815 5.73559 9.67038 5.83333 10 5.83333C10.442 5.83333 10.866 5.65774 11.1785 5.34518C11.4911 5.03262 11.6667 4.6087 11.6667 4.16667ZM11.6667 15.8333C11.6667 15.5037 11.5689 15.1815 11.3858 14.9074C11.2027 14.6333 10.9424 14.4197 10.6378 14.2935C10.3333 14.1674 9.99817 14.1344 9.67487 14.1987C9.35157 14.263 9.0546 14.4217 8.82151 14.6548C8.58842 14.8879 8.42969 15.1849 8.36538 15.5082C8.30107 15.8315 8.33407 16.1666 8.46022 16.4711C8.58637 16.7757 8.79999 17.036 9.07407 17.2191C9.34815 17.4023 9.67038 17.5 10 17.5C10.442 17.5 10.866 17.3244 11.1785 17.0118C11.4911 16.6993 11.6667 16.2754 11.6667 15.8333Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>