<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <path d="M16.2619 9.66671H11.2619V4.66671C11.2619 4.44569 11.1741 4.23373 11.0178 4.07745C10.8615 3.92117 10.6496 3.83337 10.4286 3.83337C10.2075 3.83337 9.99557 3.92117 9.83929 4.07745C9.68301 4.23373 9.59522 4.44569 9.59522 4.66671V9.66671H4.59522C4.3742 9.66671 4.16224 9.7545 4.00596 9.91079C3.84968 10.0671 3.76188 10.279 3.76188 10.5C3.76188 10.7211 3.84968 10.933 4.00596 11.0893C4.16224 11.2456 4.3742 11.3334 4.59522 11.3334H9.59522V16.3334C9.59522 16.5544 9.68301 16.7663 9.83929 16.9226C9.99557 17.0789 10.2075 17.1667 10.4286 17.1667C10.6496 17.1667 10.8615 17.0789 11.0178 16.9226C11.1741 16.7663 11.2619 16.5544 11.2619 16.3334V11.3334H16.2619C16.4829 11.3334 16.6949 11.2456 16.8511 11.0893C17.0074 10.933 17.0952 10.7211 17.0952 10.5C17.0952 10.279 17.0074 10.0671 16.8511 9.91079C16.6949 9.7545 16.4829 9.66671 16.2619 9.66671Z" fill="white"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>