<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M13.3333 5.33334L9.33333 1.82667C8.96665 1.4987 8.49196 1.31738 8 1.31738C7.50805 1.31738 7.03335 1.4987 6.66667 1.82667L2.66667 5.33334C2.45491 5.52272 2.28594 5.75504 2.17098 6.01483C2.05603 6.27463 1.99774 6.55592 2 6.84V12.6667C2 13.1971 2.21072 13.7058 2.58579 14.0809C2.96086 14.456 3.46957 14.6667 4 14.6667H12C12.5304 14.6667 13.0391 14.456 13.4142 14.0809C13.7893 13.7058 14 13.1971 14 12.6667V6.83334C14.0013 6.55038 13.9426 6.27036 13.8276 6.01179C13.7127 5.75322 13.5442 5.52197 13.3333 5.33334ZM9.33333 13.3333H6.66667V10C6.66667 9.82319 6.73691 9.65362 6.86193 9.5286C6.98696 9.40357 7.15652 9.33334 7.33333 9.33334H8.66667C8.84348 9.33334 9.01305 9.40357 9.13807 9.5286C9.2631 9.65362 9.33333 9.82319 9.33333 10V13.3333ZM12.6667 12.6667C12.6667 12.8435 12.5964 13.0131 12.4714 13.1381C12.3464 13.2631 12.1768 13.3333 12 13.3333H10.6667V10C10.6667 9.46957 10.456 8.96086 10.0809 8.58579C9.70581 8.21072 9.1971 8 8.66667 8H7.33333C6.8029 8 6.29419 8.21072 5.91912 8.58579C5.54405 8.96086 5.33334 9.46957 5.33334 10V13.3333H4C3.82319 13.3333 3.65362 13.2631 3.5286 13.1381C3.40357 13.0131 3.33334 12.8435 3.33334 12.6667V6.83334C3.33346 6.73868 3.35373 6.64513 3.39281 6.55892C3.4319 6.47271 3.48889 6.39581 3.56 6.33334L7.56 2.83334C7.68166 2.72646 7.83806 2.66751 8 2.66751C8.16194 2.66751 8.31834 2.72646 8.44 2.83334L12.44 6.33334C12.5111 6.39581 12.5681 6.47271 12.6072 6.55892C12.6463 6.64513 12.6665 6.73868 12.6667 6.83334V12.6667Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>