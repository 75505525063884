import appointmentService from "../services/appointmentService"
import appointmentPb from "../grpc/generated/appointment_pb"
import { EmptyRequest } from "../grpc/generated/appointment_pb"

const ListPatientAppointments = async (listPatientParams) => {
    try {
        const req = new appointmentPb.ListPatientAppointmentsRequest(listPatientParams);
        console.log("Actions", listPatientParams.value)
        req.setType(listPatientParams.value.type);
        req.setInitialdate(listPatientParams.value.initialDate);
        req.setFinaldate(listPatientParams.value.finalDate);
        req.addWord(listPatientParams.value.word);
        

        const res = await appointmentService.listPatientAppointments(req);
        console.log("res", res)
        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

const AddAppointmentPatient = async (appointmentInfosArray) => {
    try {
        const req = new appointmentPb.AddAppointmentPatientRequest(appointmentInfosArray);
        console.log("O que está chegando...", appointmentInfosArray);

        req.setServiceTypeId(appointmentInfosArray?.selectedServiceTypeId);
        req.setSpeciality();
        req.setActuation();
        req.setWaitingList(false);
        req.setDescriptionWaitingList();
        req.setChiefComplaint(appointmentInfosArray?.chiefComplaint);

        // Verificar se as strings de data e hora são fornecidas
        if (!appointmentInfosArray.date || !appointmentInfosArray.hour) {
            throw new Error("As strings de data e hora são obrigatórias.");
        }

        // Dividir a string de data no formato yyyy-mm-dd
        const [year, month, day] = appointmentInfosArray.date.split('-');

        // Dividir a string de hora no formato HH:mm
        const [hour, minute] = appointmentInfosArray.hour.split(':');

        // Criar um objeto Date com os componentes da data e hora no fuso horário de Brasília
        const dateObj = new Date(Date.UTC(year, month - 1, day, hour, minute));

        // Ajustar para o fuso horário de Brasília (UTC-3)
        dateObj.setUTCHours(dateObj.getUTCHours() - 3);

        // Formatar a data conforme o formato desejado ISO 8601 com fuso horário de Brasília
        const formattedDate = dateObj.toISOString().replace('.000Z', '-03:00');

        console.log("Data formatada", formattedDate);

        // Adicionar o novo parâmetro ao request
        req.setDateComplete(formattedDate);

        const res = await appointmentService.addAppointmentPatient(req);

        if (res && res.data) {
            let parsedData;
            try {
                parsedData = JSON.parse(res.data);
                console.log("Dados convertidos:", parsedData);
            } catch (error) {
                console.error("Erro ao converter JSON:", error);
            }
        }

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export default {
    ListPatientAppointments,
    AddAppointmentPatient
}