<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path d="M9 1.5C7.4087 1.5 5.88258 2.13214 4.75736 3.25736C3.63214 4.38258 3 5.9087 3 7.5C3 11.55 8.2875 16.125 8.5125 16.32C8.64835 16.4362 8.82124 16.5 9 16.5C9.17876 16.5 9.35165 16.4362 9.4875 16.32C9.75 16.125 15 11.55 15 7.5C15 5.9087 14.3679 4.38258 13.2426 3.25736C12.1174 2.13214 10.5913 1.5 9 1.5ZM9 14.7375C7.4025 13.2375 4.5 10.005 4.5 7.5C4.5 6.30653 4.97411 5.16193 5.81802 4.31802C6.66193 3.47411 7.80653 3 9 3C10.1935 3 11.3381 3.47411 12.182 4.31802C13.0259 5.16193 13.5 6.30653 13.5 7.5C13.5 10.005 10.5975 13.245 9 14.7375ZM9 4.5C8.40666 4.5 7.82664 4.67595 7.33329 5.00559C6.83994 5.33524 6.45542 5.80377 6.22836 6.35195C6.0013 6.90013 5.94189 7.50333 6.05764 8.08527C6.1734 8.66721 6.45912 9.20176 6.87868 9.62132C7.29824 10.0409 7.83279 10.3266 8.41473 10.4424C8.99667 10.5581 9.59987 10.4987 10.1481 10.2716C10.6962 10.0446 11.1648 9.66006 11.4944 9.16671C11.8241 8.67336 12 8.09334 12 7.5C12 6.70435 11.6839 5.94129 11.1213 5.37868C10.5587 4.81607 9.79565 4.5 9 4.5ZM9 9C8.70333 9 8.41332 8.91203 8.16665 8.7472C7.91997 8.58238 7.72771 8.34811 7.61418 8.07403C7.50065 7.79994 7.47094 7.49834 7.52882 7.20737C7.5867 6.91639 7.72956 6.64912 7.93934 6.43934C8.14912 6.22956 8.41639 6.0867 8.70736 6.02882C8.99834 5.97094 9.29994 6.00065 9.57403 6.11418C9.84811 6.22771 10.0824 6.41997 10.2472 6.66665C10.412 6.91332 10.5 7.20333 10.5 7.5C10.5 7.89783 10.342 8.27936 10.0607 8.56066C9.77936 8.84197 9.39782 9 9 9Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>