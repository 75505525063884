<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.2733 5.76C14.2733 5.76 14.2733 5.76 14.2733 5.72667C13.8036 4.44415 12.9512 3.33686 11.8316 2.55458C10.712 1.7723 9.37913 1.35278 8.0133 1.35278C6.64747 1.35278 5.31459 1.7723 4.19497 2.55458C3.07536 3.33686 2.22303 4.44415 1.7533 5.72667C1.7533 5.72667 1.7533 5.72667 1.7533 5.76C1.22872 7.20727 1.22872 8.79274 1.7533 10.24C1.7533 10.24 1.7533 10.24 1.7533 10.2733C2.22303 11.5559 3.07536 12.6631 4.19497 13.4454C5.31459 14.2277 6.64747 14.6472 8.0133 14.6472C9.37913 14.6472 10.712 14.2277 11.8316 13.4454C12.9512 12.6631 13.8036 11.5559 14.2733 10.2733C14.2733 10.2733 14.2733 10.2733 14.2733 10.24C14.7979 8.79274 14.7979 7.20727 14.2733 5.76ZM2.83997 9.33334C2.60879 8.45948 2.60879 7.54053 2.83997 6.66667H4.07997C3.97332 7.55236 3.97332 8.44765 4.07997 9.33334H2.83997ZM3.38663 10.6667H4.31997C4.47645 11.2612 4.70011 11.836 4.98663 12.38C4.33283 11.9346 3.7863 11.3494 3.38663 10.6667ZM4.31997 5.33334H3.38663C3.78055 4.65273 4.3201 4.06765 4.96663 3.62C4.687 4.16483 4.47006 4.73961 4.31997 5.33334ZM7.3333 13.1333C6.51448 12.5325 5.9394 11.6568 5.7133 10.6667H7.3333V13.1333ZM7.3333 9.33334H5.42663C5.30223 8.4488 5.30223 7.5512 5.42663 6.66667H7.3333V9.33334ZM7.3333 5.33334H5.7133C5.9394 4.34318 6.51448 3.46755 7.3333 2.86667V5.33334ZM12.6133 5.33334H11.68C11.5235 4.73877 11.2998 4.16396 11.0133 3.62C11.6671 4.06538 12.2136 4.65063 12.6133 5.33334ZM8.66663 2.86667C9.48546 3.46755 10.0605 4.34318 10.2866 5.33334H8.66663V2.86667ZM8.66663 13.1333V10.6667H10.2866C10.0605 11.6568 9.48546 12.5325 8.66663 13.1333ZM10.5733 9.33334H8.66663V6.66667H10.5733C10.6977 7.5512 10.6977 8.4488 10.5733 9.33334ZM11.0333 12.38C11.3198 11.836 11.5435 11.2612 11.7 10.6667H12.6333C12.2336 11.3494 11.6871 11.9346 11.0333 12.38ZM13.16 9.33334H11.92C11.9742 8.89097 12.0009 8.44567 12 8C12.0007 7.55434 11.974 7.10905 11.92 6.66667H13.16C13.3911 7.54053 13.3911 8.45948 13.16 9.33334Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>