<template>
    <div class="no-card">
        <div class="no-items flex flex-column w-full">
            <span class="item justify-content-center align-items-center"
                :style="{ color: '#BDBDBD', letterSpacing: '.5px !important' }">
                Nenhum compromisso nessa(s) data(s).
            </span>
        </div>
    </div>
</template>

<script>
export default {

    setup(props) {


        return {}
    }
}
</script>

<style lang="scss" scoped>
.no-card {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 8px;
    gap: 8px;
    margin-bottom: 22px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    .no-items {
        min-height: 60px;
        .item {
            min-height: 60px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            width: 100%;
            padding: 10px;
            background: white;
            box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
            border-radius: 8px;
        }
    }
}
</style>