<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12.6666 2.66665H11.3333V1.99998C11.3333 1.82317 11.2631 1.6536 11.1381 1.52858C11.013 1.40355 10.8435 1.33331 10.6666 1.33331C10.4898 1.33331 10.3203 1.40355 10.1952 1.52858C10.0702 1.6536 9.99998 1.82317 9.99998 1.99998V2.66665H5.99998V1.99998C5.99998 1.82317 5.92974 1.6536 5.80472 1.52858C5.67969 1.40355 5.51012 1.33331 5.33331 1.33331C5.1565 1.33331 4.98693 1.40355 4.86191 1.52858C4.73688 1.6536 4.66665 1.82317 4.66665 1.99998V2.66665H3.33331C2.80288 2.66665 2.29417 2.87736 1.9191 3.25243C1.54403 3.62751 1.33331 4.13621 1.33331 4.66665V12.6666C1.33331 13.1971 1.54403 13.7058 1.9191 14.0809C2.29417 14.4559 2.80288 14.6666 3.33331 14.6666H12.6666C13.1971 14.6666 13.7058 14.4559 14.0809 14.0809C14.4559 13.7058 14.6666 13.1971 14.6666 12.6666V4.66665C14.6666 4.13621 14.4559 3.62751 14.0809 3.25243C13.7058 2.87736 13.1971 2.66665 12.6666 2.66665ZM13.3333 12.6666C13.3333 12.8435 13.2631 13.013 13.1381 13.1381C13.013 13.2631 12.8435 13.3333 12.6666 13.3333H3.33331C3.1565 13.3333 2.98693 13.2631 2.86191 13.1381C2.73688 13.013 2.66665 12.8435 2.66665 12.6666V7.99998H13.3333V12.6666ZM13.3333 6.66665H2.66665V4.66665C2.66665 4.48984 2.73688 4.32027 2.86191 4.19524C2.98693 4.07022 3.1565 3.99998 3.33331 3.99998H4.66665V4.66665C4.66665 4.84346 4.73688 5.01303 4.86191 5.13805C4.98693 5.26308 5.1565 5.33331 5.33331 5.33331C5.51012 5.33331 5.67969 5.26308 5.80472 5.13805C5.92974 5.01303 5.99998 4.84346 5.99998 4.66665V3.99998H9.99998V4.66665C9.99998 4.84346 10.0702 5.01303 10.1952 5.13805C10.3203 5.26308 10.4898 5.33331 10.6666 5.33331C10.8435 5.33331 11.013 5.26308 11.1381 5.13805C11.2631 5.01303 11.3333 4.84346 11.3333 4.66665V3.99998H12.6666C12.8435 3.99998 13.013 4.07022 13.1381 4.19524C13.2631 4.32027 13.3333 4.48984 13.3333 4.66665V6.66665Z" fill="#828282"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>