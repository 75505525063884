<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M14.5333 6.66665C14.2666 5.86665 13.4666 5.33331 12.6666 5.33331C11.5333 5.33331 10.6666 6.19998 10.6666 7.33331C10.6666 8.19998 11.2 8.93331 12 9.19998V10.3333C12 12 10.6666 13.3333 8.99998 13.3333C7.33331 13.3333 5.99998 12 5.99998 10.3333V9.93331C7.93331 9.59998 9.33331 7.93331 9.33331 5.99998V1.99998C9.33331 1.59998 9.06665 1.33331 8.66665 1.33331H7.33331C6.93331 1.33331 6.66665 1.59998 6.66665 1.99998C6.66665 2.39998 6.93331 2.66665 7.33331 2.66665H7.99998V5.99998C7.99998 7.46665 6.79998 8.66665 5.33331 8.66665C3.86665 8.66665 2.66665 7.46665 2.66665 5.99998V2.66665H3.33331C3.73331 2.66665 3.99998 2.39998 3.99998 1.99998C3.99998 1.59998 3.73331 1.33331 3.33331 1.33331H1.99998C1.59998 1.33331 1.33331 1.59998 1.33331 1.99998V5.99998C1.33331 7.93331 2.73331 9.59998 4.66665 9.93331V10.3333C4.66665 12.7333 6.59998 14.6666 8.99998 14.6666C11.4 14.6666 13.3333 12.7333 13.3333 10.3333V9.19998C14.4 8.86665 14.9333 7.73331 14.5333 6.66665Z" fill="#BDBDBD"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

// ::v-deep(.) {

// }
</style>