<template>
    <div class="all">
        <Skeleton width="100%" height="40px"></Skeleton>
    </div>
    <div class="header-container">
        <div class="left">
            <Skeleton width="300px" height="40px"></Skeleton>
        </div>
        <div class="right">
            <div class="add">
                <Skeleton width="150px" height="40px"></Skeleton>
            </div>
            <div class="add">
                <Skeleton width="2px" height="20px"></Skeleton>
            </div>
            <div class="filters">
                <Skeleton width="150px" height="40px"></Skeleton>
            </div>
            <div class="search">
                <Skeleton width="260px" height="40px"></Skeleton>
            </div>
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";
import Skeleton from 'primevue/skeleton';

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>

.all {
    border-radius: 200px;
    gap: 8px;
    overflow: hidden;
    position: relative;

    @media (min-width: 1140px) {
        display: none;
    }
}

.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // width: 100%;

    .left {
        display: flex;

        max-width: 300px;
        border-radius: 200px;
        gap: 8px;
        overflow: hidden;
        position: relative;
    }
        
    .right {
        display: flex;
        align-items: center;

        max-width: 800px;
        gap: 10px;

        .add {
            border-radius: 200px;
            overflow: hidden;
            position: relative;
        }

        .filters {
            border-radius: 200px;
            overflow: hidden;
            position: relative;
        }

        .search {
            border-radius: 200px;
            overflow: hidden;
            position: relative;
        }
    } 

    @media (max-width: 1140px) {
            display: none;
    };
}

</style>